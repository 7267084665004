import React, { useState, useEffect } from 'react';
import { FaProjectDiagram } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import EditButton from '~/components/Buttons/Edit';
import ViewButton from '~/components/Buttons/View';
import Pagination from '~/components/Pagination';

import { getFormattedCnpj } from '~/utils';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  LinkView,
} from './styles';

interface IProject {
  id: string;
  type: string;
  code: string;
  erp_rep_code: string;
  status: string;
  gross_discount_price: number;
  type_description: string;
  display?: {
    name: string;
  };
  material?: {
    name: string;
  };
  sponsorship?: {
    name: string;
  };
  adversity?: {
    name: string;
  };
  folder?: {
    name: string;
  };
  event?: {
    name: string;
  };
  local_client: {
    company_name: string;
    document: string;
    city: string;
    state: string;
  };
  project_procedure: {
    id: string;
    sequence: number;
    process: string;
    user_type: string;
    event_validator: boolean;
  };
  created_at: string;
}

const AMOUNT_PER_PAGE = 10;

const List: React.FC = () => {
  const { user } = useAuth();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [projects, setProjects] = useState<IProject[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadProjects = async () => {
      await api
        .get(
          `/projects?type=ALL` +
            `&page=${page}` +
            `&filter=${filter}` +
            `&amount=${AMOUNT_PER_PAGE}` +
            `&user_type=${user.user_type.type}` +
            `&erp_rep_code=${
              user.user_type.type === 'REP' ? user.erp_rep_code : ''
            }` +
            `&search_type=pending_by_user_type`,
        )
        .then(response => {
          setProjects(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadProjects();
  }, [page, filter, user]);

  function getProjectItemName(projectReceived: IProject): string | undefined {
    if (projectReceived.type === 'DISPLAY') {
      return projectReceived.display?.name.substring(0, 50);
    }
    if (projectReceived.type === 'MATERIAL') {
      return projectReceived.material?.name.substring(0, 50);
    }
    if (projectReceived.type === 'PATROCINIO') {
      return projectReceived.sponsorship?.name.substring(0, 50);
    }
    if (projectReceived.type === 'ADVERSIDADE') {
      return projectReceived.adversity?.name.substring(0, 50);
    }
    if (projectReceived.type === 'PASTA') {
      return projectReceived.folder?.name.substring(0, 50);
    }
    if (projectReceived.type === 'EVENTO') {
      return projectReceived.event?.name.substring(0, 50);
    }

    return '';
  }

  function getEditPath(projectReceived: IProject): string {
    if (projectReceived.type === 'DISPLAY') {
      return (
        `/projects/displays/procedure` +
        `${projectReceived.project_procedure.sequence}`
      );
    }
    if (projectReceived.type === 'MATERIAL') {
      return (
        `/projects/materials/procedure` +
        `${projectReceived.project_procedure.sequence}`
      );
    }
    if (projectReceived.type === 'PATROCINIO') {
      return (
        `/projects/sponsorships/procedure` +
        `${projectReceived.project_procedure.sequence}`
      );
    }
    if (projectReceived.type === 'ADVERSIDADE') {
      return (
        `/projects/adversities/procedure` +
        `${projectReceived.project_procedure.sequence}`
      );
    }
    if (projectReceived.type === 'PASTA') {
      return (
        `/projects/folders/procedure` +
        `${projectReceived.project_procedure.sequence}`
      );
    }
    if (projectReceived.type === 'AMOSTRA') {
      return (
        `/projects/samples/procedure` +
        `${projectReceived.project_procedure.sequence}`
      );
    }
    if (projectReceived.type === 'EVENTO') {
      return (
        `/projects/events/procedure` +
        `${projectReceived.project_procedure.sequence}`
      );
    }

    return '';
  }

  function getViewPath(projectReceived: IProject): string {
    if (projectReceived.type === 'DISPLAY') {
      return `/projects/displays/view`;
    }
    if (projectReceived.type === 'MATERIAL') {
      return `/projects/materials/view`;
    }
    if (projectReceived.type === 'PATROCINIO') {
      return `/projects/sponsorships/view`;
    }
    if (projectReceived.type === 'ADVERSIDADE') {
      return `/projects/adversities/view`;
    }
    if (projectReceived.type === 'PASTA') {
      return `/projects/folders/view`;
    }
    if (projectReceived.type === 'AMOSTRA') {
      return `/projects/samples/view`;
    }
    if (projectReceived.type === 'EVENTO') {
      return `/projects/events/view`;
    }

    return '';
  }

  function handleLinkEditDisabled(projectReceived: IProject): boolean {
    if (projectReceived.status.toUpperCase() === 'CANCELADO') {
      return true;
    }
    if (projectReceived.status.toUpperCase() === 'FINALIZADO') {
      return true;
    }
    if (user.user_type.type === projectReceived.project_procedure.user_type) {
      return false;
    }
    if (user.user_type.type === 'ADM') {
      return false;
    }
    if (
      projectReceived.project_procedure.event_validator &&
      user.event_validator
    ) {
      return false;
    }
    if (
      projectReceived.type === 'PATROCINIO' &&
      projectReceived.project_procedure.process === 'APROVACAO_GERAL' &&
      user.spons_gen_approval_perm &&
      (!user.spons_gen_approval_min_price ||
        user.spons_gen_approval_min_price <=
          projectReceived.gross_discount_price) &&
      (!user.spons_gen_approval_max_price ||
        user.spons_gen_approval_max_price >=
          projectReceived.gross_discount_price)
    ) {
      return false;
    }

    return true;
  }

  function handleTable(): React.ReactNode {
    if (projects) {
      if (projects.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="10%" textAlign="left">
                  Data Abertura
                </Th>
                <Th width="10%" textAlign="left">
                  Código
                </Th>
                <Th width="20%" textAlign="left">
                  Cliente
                </Th>
                <Th width="10%" textAlign="left">
                  Cidade
                </Th>
                <Th width="5%" textAlign="left">
                  UF
                </Th>
                <Th width="15%" textAlign="left">
                  Item
                </Th>
                <Th width="10%" textAlign="left">
                  Tipo
                </Th>
                <Th width="10%" textAlign="left">
                  Situação
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {projects?.map(project => (
                <TbodyTr key={project.id}>
                  <Td width="10%" textAlign="left" heading="DATA ABERTURA">
                    {new Date(project.created_at).toLocaleDateString()}
                  </Td>
                  <Td width="10%" textAlign="left" heading="CÓDIGO">
                    {project.code}
                  </Td>
                  <Td width="20%" textAlign="left" heading="CLIENTE">
                    {`${project.local_client.company_name} (${getFormattedCnpj(
                      project.local_client.document,
                    )})`}
                  </Td>
                  <Td width="10%" textAlign="left" heading="CIDADE">
                    {project.local_client.city}
                  </Td>
                  <Td width="5%" textAlign="left" heading="UF">
                    {project.local_client.state}
                  </Td>
                  <Td width="15%" textAlign="left" heading="ITEM">
                    {getProjectItemName(project)}
                  </Td>
                  <Td width="10%" textAlign="left" heading="TIPO">
                    {project.type_description}
                  </Td>
                  <Td width="10%" textAlign="left" heading="SITUAÇÃO">
                    {project.status}
                  </Td>
                  <Td
                    width="10%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    {!handleLinkEditDisabled(project) && (
                      <Link
                        to={{
                          pathname: getEditPath(project),
                          state: project,
                        }}
                      >
                        <EditButton />
                      </Link>
                    )}

                    <LinkView
                      to={{
                        pathname: getViewPath(project),
                        state: project,
                      }}
                    >
                      <ViewButton />
                    </LinkView>
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </tr>
        </tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChild="Solicitações Pendentes"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaProjectDiagram}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      {handleTable()}

      <div style={{ display: 'flex', marginTop: '2rem' }}>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />
      </div>
    </Container>
  );
};

export default List;
