import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PropagateLoader } from 'react-spinners';
import { FaProjectDiagram, FaCalendarAlt, FaWrench } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import TableSelect from '~/components/TableSelect';
import TableCurrencyInput from '~/components/TableInputs/Currency';
import TableTextArea from '~/components/TableTextArea';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import DeletePreviousButton from '~/components/Buttons/DeletePrevious';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import ConfirmWarningDialog from '~/components/Dialogs/ConfirmWarning';
import DeleteModal from '~/components/Modals/OldDelete';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  LabelContainer,
  Label,
  Table,
  ItemSequence,
  ItemSequenceLabel,
  ItemContent,
  ItemWrapper,
  ButtonContainer,
} from './styles';

interface IPreviousProjectEventCost {
  id: string;
  sequence: number;
  price: number;
  note: string;
  event_cost_type_id: string;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
}

interface IProjectEventCost {
  id: string;
  sequence: number;
  price: number;
  note: string;
  event_cost_type: {
    value: string;
  };
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project_record_reason: string;
  previous: boolean;
  fields_enabled: boolean;
  deleted: boolean;
}

interface IFormEventCosts {
  project_event_costs: IProjectEventCost[];
  received_user_id: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
}

const EventCosts: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [eventCostTypes, setEventCostTypes] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const [previousProjectEventCosts, setPreviousProjectEventCosts] =
    useState<IPreviousProjectEventCost[]>();
  const [projectEventCosts, setProjectEventCosts] = useState<
    IProjectEventCost[]
  >([]);
  const [loadingEventCostTypes, setLoadingEventCostTypes] =
    useState<boolean>(true);
  const [loadingProjectEventCosts, setLoadingProjectEventCosts] =
    useState<boolean>(true);
  const [loadProjectEventCosts, setloadProjectEventCosts] = useState(false);
  const [projectEventCostsLoaded, setProjectEventCostsLoaded] = useState(false);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');
  const [reasonDelete, setReasonDelete] = useState('');
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const projectEventCostSequence = useRef(0);
  const [eventCostEnableSequence, setEventCostEnableSequence] = useState('');
  const [confirmEventCostEnabled, setConfirmEventCostEnabled] = useState(false);
  const [eventCostDeleteSequence, setEventCostDeleteSequence] = useState('');
  const [confirmEventCostDelete, setConfirmEventCostDelete] = useState(false);

  const { handleSubmit, register, setValue } = useForm<IFormEventCosts>();

  useEffect(() => {
    if (loadProjectEventCosts) {
      if (previousProjectEventCosts) {
        if (previousProjectEventCosts.length > 0) {
          Object.keys(previousProjectEventCosts).forEach(key => {
            projectEventCostSequence.current =
              previousProjectEventCosts[Number(key)].sequence;

            const previousProjectEventCost: IProjectEventCost = {
              id: previousProjectEventCosts[Number(key)].id,
              sequence: previousProjectEventCosts[Number(key)].sequence,
              price: previousProjectEventCosts[Number(key)].price,
              note: previousProjectEventCosts[Number(key)].note,
              event_cost_type: {
                value:
                  previousProjectEventCosts[Number(key)].event_cost_type_id,
              },
              project_id: previousProjectEventCosts[Number(key)].project_id,
              user_inclusion_id:
                previousProjectEventCosts[Number(key)].user_inclusion_id,
              user_update_id: user.id,
              project_record_reason: '',
              previous: true,
              fields_enabled: false,
              deleted: false,
            };

            setProjectEventCosts(prevProjectEventCosts => [
              ...prevProjectEventCosts,
              previousProjectEventCost,
            ]);
          });
        }
      }

      setloadProjectEventCosts(false);
      setProjectEventCostsLoaded(true);
      setLoadingProjectEventCosts(false);
    }
  }, [state, user, loadProjectEventCosts, previousProjectEventCosts]);

  useEffect(() => {
    if (previousProjectEventCosts) {
      if (!projectEventCostsLoaded) {
        setloadProjectEventCosts(true);
      }
    }
  }, [projectEventCostsLoaded, previousProjectEventCosts]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Dados não localizados',
      });

      history.push('/projects/events');
    } else if (
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/events');
    } else {
      register('project_event_costs');
      register('received_user_id');

      setValue('status', { value: 'Cancelado' });
      setValue('received_user_id', user.id);

      api.get('/configurations/eventcosttypes/valid').then(response => {
        setEventCostTypes(response.data);
        setLoadingEventCostTypes(false);
      });

      api
        .get(`/projecteventcosts/projectall?project_id=${state.id}`)
        .then(response => {
          setPreviousProjectEventCosts(response.data);
        });
    }
  }, [state, addToast, history, user, register, setValue]);

  const options = eventCostTypes?.map(eventCostType => ({
    value: eventCostType.id,
    label: eventCostType.name,
  }));

  const submitForm = useCallback(
    async (data: IFormEventCosts) => {
      try {
        setIsSaving(true);

        if (state && state.id) {
          await api.put(`/projects/updateeventcosts/${state.id}`, data);

          addToast({
            type: 'success',
            title: 'Solicitação atualizada!',
            description: 'Solicitação atualizada com sucesso.',
          });
        }

        history.push('/projects/events');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [state, addToast, history],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    const rowsInvalidType = projectEventCosts.filter(
      projEventCost => projEventCost.event_cost_type.value === '',
    );

    const rowsInvalidPrice = projectEventCosts.filter(
      projEventCost => projEventCost.price <= 0,
    );

    const rowsInvalidNote = projectEventCosts.filter(
      projEventCost => projEventCost.note === '',
    );

    if (projectEventCosts.length <= 0) {
      setTitleNotice('Custos inválidos!');
      setSubTitleNotice('Necessário informar custos!');
      setIsOpenNotice(true);
    } else if (rowsInvalidType && rowsInvalidType.length > 0) {
      setTitleNotice('Tipos inválidos em custos!');
      setSubTitleNotice(
        'Não é possível enviar projeto com registro de custos sem tipo selecionado!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidPrice && rowsInvalidPrice.length > 0) {
      setTitleNotice('Valores inválidos em custos!');
      setSubTitleNotice(
        'Não é possível enviar projeto com registro de custos sem valor!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidNote && rowsInvalidNote.length > 0) {
      setTitleNotice('Existem custos sem observação!');
      setSubTitleNotice(
        'Não é possível enviar projeto com custos sem observação!',
      );
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(
        `Confirma alteração em registros de custos do evento na solicitação ${state.code}?`,
      );
      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue('project_event_costs', projectEventCosts);
    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    history.push('/projects/events');
  }, [history]);

  function onConfirmWarning(): void {
    setConfirmEventCostEnabled(true);
  }

  function onDelete(): void {
    setConfirmEventCostDelete(true);
  }

  function handleAddRowTableProjectEventCosts(): void {
    projectEventCostSequence.current += 1;

    const newProjectEventCost: IProjectEventCost = {
      id: '',
      sequence: projectEventCostSequence.current,
      price: 0,
      note: '',
      event_cost_type: { value: '' },
      project_id: state.id,
      user_inclusion_id: user.id,
      user_update_id: user.id,
      project_record_reason: '',
      previous: false,
      fields_enabled: true,
      deleted: false,
    };

    setProjectEventCosts(prevProjectEventCosts => [
      ...prevProjectEventCosts,
      newProjectEventCost,
    ]);
  }

  function handleDeleteRowTableProjectEventCosts(): void {
    setProjectEventCosts(
      projectEventCosts.filter(
        projEventCost =>
          projEventCost.sequence !== projectEventCostSequence.current,
      ),
    );

    projectEventCostSequence.current -= 1;
  }

  async function handleRowEventCostTypeChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectEventCosts(
      projectEventCosts.map(projEventCost => {
        if (projEventCost.sequence === sequence) {
          projEventCost.event_cost_type.value = fieldValue;
        }
        return projEventCost;
      }),
    );
  }

  function handleRowEventCostsPriceChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let priceConv = fieldValue;
    let priceValue = 0;

    priceConv = priceConv.replaceAll('R$', '');
    priceConv = priceConv.replaceAll('.', '');
    priceConv = priceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(priceConv)))) {
      priceValue = parseFloat(priceConv);
    }

    setProjectEventCosts(
      projectEventCosts.map(projEventCost => {
        if (projEventCost.sequence === sequence) {
          projEventCost.price = priceValue;
        }
        return projEventCost;
      }),
    );
  }

  async function handleRowEventCostsNoteChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectEventCosts(
      projectEventCosts.map(projEventCost => {
        if (projEventCost.sequence === sequence) {
          projEventCost.note = fieldValue;
        }
        return projEventCost;
      }),
    );
  }

  function getEventCostTypeLabel(event_cost_type_id: string): string {
    if (eventCostTypes) {
      if (eventCostTypes.length > 0) {
        const eventCostType = eventCostTypes.find(
          eventCostTp => eventCostTp.id === event_cost_type_id,
        );

        if (eventCostType) {
          return eventCostType.name;
        }
      }
    }

    return 'Selecione ...';
  }

  function handleRowFieldsEnabledFalseChange(sequence: number): void {
    setProjectEventCosts(
      projectEventCosts.map(projEventCost => {
        if (projEventCost.sequence === sequence) {
          projEventCost.fields_enabled = false;
        }
        return projEventCost;
      }),
    );
  }

  useEffect(() => {
    if (confirmEventCostEnabled) {
      if (eventCostEnableSequence) {
        if (eventCostEnableSequence !== '') {
          setProjectEventCosts(
            projectEventCosts.map(projEventCost => {
              if (projEventCost.sequence === Number(eventCostEnableSequence)) {
                projEventCost.fields_enabled = true;
              }
              return projEventCost;
            }),
          );
          setEventCostEnableSequence('');
          setConfirmEventCostEnabled(false);
        }
      }
    }
  }, [confirmEventCostEnabled, eventCostEnableSequence, projectEventCosts]);

  useEffect(() => {
    if (confirmEventCostDelete) {
      if (eventCostDeleteSequence) {
        if (eventCostDeleteSequence !== '') {
          setProjectEventCosts(
            projectEventCosts.map(projEventCost => {
              if (projEventCost.sequence === Number(eventCostDeleteSequence)) {
                projEventCost.deleted = true;
                projEventCost.project_record_reason = reasonDelete;
              }
              return projEventCost;
            }),
          );

          setEventCostDeleteSequence('');
          setConfirmEventCostDelete(false);
          setReasonDelete('');
        }
      }
    }
  }, [
    confirmEventCostDelete,
    eventCostDeleteSequence,
    projectEventCosts,
    reasonDelete,
  ]);

  function handleProjectEventCostsTableRowEnableFieldsButtons(
    rowProjectEventCost: IProjectEventCost,
  ): React.ReactNode {
    if (rowProjectEventCost.fields_enabled) {
      return (
        <UnlockButton
          iconSize={20}
          type="button"
          onClick={() => {
            handleRowFieldsEnabledFalseChange(rowProjectEventCost.sequence);
          }}
        />
      );
    }

    return (
      <LockButton
        iconSize={20}
        type="button"
        onClick={() => {
          setEventCostEnableSequence(rowProjectEventCost.sequence.toString());
          setIsOpenWarning(true);
        }}
      />
    );
  }

  function handleDeleteRow(
    projectEventCostReceived: IProjectEventCost,
  ): React.ReactNode | null {
    if (
      projectEventCostReceived.sequence === projectEventCostSequence.current
    ) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => {
            handleDeleteRowTableProjectEventCosts();
          }}
        />
      );
    }

    return null;
  }

  function handleProjectEventCostsTableRowButtons(
    rowProjectEventCost: IProjectEventCost,
  ): React.ReactNode | null {
    if (rowProjectEventCost.previous) {
      return (
        <>
          {handleProjectEventCostsTableRowEnableFieldsButtons(
            rowProjectEventCost,
          )}

          <DeletePreviousButton
            iconSize={20}
            marginTop="0.8rem"
            marginLeft="1rem"
            type="button"
            onClick={() => {
              setEventCostDeleteSequence(
                rowProjectEventCost.sequence.toString(),
              );
              setTitleDelete(
                `Deseja realmente excluir o custo ` +
                  `"${getEventCostTypeLabel(
                    rowProjectEventCost.event_cost_type.value,
                  )}" ` +
                  `previamente registrado?`,
              );
              setSubTitleDelete(
                'A exclusão apenas será efetivada ao salvar o procedimento!',
              );
              setIsOpenDelete(true);
            }}
          />
        </>
      );
    }

    return handleDeleteRow(rowProjectEventCost);
  }

  function handleProjectEventCostsTableRow(
    rowProjectEventCost: IProjectEventCost,
  ): React.ReactNode | null {
    if (!rowProjectEventCost.deleted) {
      return (
        <tr key={rowProjectEventCost.sequence}>
          <ItemSequence>
            <ItemSequenceLabel>
              {`${'Sequência'.toUpperCase()}: ${rowProjectEventCost.sequence}`}
            </ItemSequenceLabel>
          </ItemSequence>

          <ItemContent>
            <ItemWrapper>
              <TableSelect
                label="Tipo"
                upperCaseLabel
                options={options}
                loading={loadingEventCostTypes}
                defaultValue={{
                  value: rowProjectEventCost.event_cost_type.value,
                  label: getEventCostTypeLabel(
                    rowProjectEventCost.event_cost_type.value,
                  ),
                }}
                disabled={
                  rowProjectEventCost.previous &&
                  !rowProjectEventCost.fields_enabled
                }
                onSet={(e: any) => {
                  handleRowEventCostTypeChange(
                    rowProjectEventCost.sequence,
                    e.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableCurrencyInput
                type="text"
                labelText="Valor"
                upperCaseLabel
                defaultValue={rowProjectEventCost.price}
                disabled={
                  rowProjectEventCost.previous &&
                  !rowProjectEventCost.fields_enabled
                }
                onSet={e => {
                  handleRowEventCostsPriceChange(
                    rowProjectEventCost.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <ItemContent>
            <ItemWrapper>
              <TableTextArea
                labelText="Observação"
                upperCaseLabel
                defaultValue={rowProjectEventCost.note}
                disabled={
                  rowProjectEventCost.previous &&
                  !rowProjectEventCost.fields_enabled
                }
                maxLength={2000}
                onChange={e => {
                  handleRowEventCostsNoteChange(
                    rowProjectEventCost.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 5px 10px 10px',
            }}
          >
            {handleProjectEventCostsTableRowButtons(rowProjectEventCost)}
          </div>
        </tr>
      );
    }

    return null;
  }

  function handleProjectEventCostsTable(): React.ReactNode {
    if (projectEventCosts) {
      if (projectEventCosts.length > 0) {
        return (
          <Table>
            {loadingProjectEventCosts && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectEventCosts?.map(projectEventCost =>
              handleProjectEventCostsTableRow(projectEventCost),
            )}
          </Table>
        );
      }
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/events"
        firstChild="Eventos"
        secondChild="Alteração Manual de Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        secondChildIcon={FaWrench}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} all />

      <form onSubmit={handleSubmit(submitForm)}>
        <LabelContainer>
          <Label>Custos</Label>
        </LabelContainer>

        <Content maxWidth="680px">{handleProjectEventCostsTable()}</Content>

        <ContentAddRow maxWidth="680px">
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectEventCosts()}
          />
        </ContentAddRow>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <ConfirmWarningDialog
        isOpen={isOpenWarning}
        setIsOpen={setIsOpenWarning}
        onConfirm={onConfirmWarning}
        title="Deseja realmente alterar o custo previamente registrado?"
        opConfirm="Sim, alterar"
      />

      <DeleteModal
        isOpen={isOpenDelete}
        onDelete={onDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        setReason={setReasonDelete}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default EventCosts;
