import React, { useState } from 'react';
import { FcHighPriority } from 'react-icons/fc';

import { useTheme } from 'styled-components';

import DefaultTooltip from '~/components/Tooltips/Default';
import TextArea from '~/components/TextArea';

import {
  StyledModal,
  Title,
  SubTitle,
  OptionConfirm,
  OptionCancel,
} from './styles';

interface IDeleteProps {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onDelete: (reason: string) => void;
  onClose: () => void;
}

const Delete: React.FC<IDeleteProps> = ({
  isOpen,
  title,
  subTitle,
  onDelete,
  onClose,
}: IDeleteProps) => {
  const { colors, fontSizes } = useTheme();
  const [reason, setReason] = useState('');

  const handleClose = (): void => {
    setReason('');
    onClose();
  };

  const handleConfirmation = (): void => {
    setReason('');
    onDelete(reason);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div style={{ padding: '1rem' }}>
        <div style={{ fontSize: fontSizes.xLarge, textAlign: 'center' }}>
          <FcHighPriority size={60} color="#c9aa71" />
        </div>

        <Title>{title}</Title>

        {!!subTitle && <SubTitle>{subTitle}</SubTitle>}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
        >
          <div style={{ width: '100%', padding: '0 1rem 1rem 1rem' }}>
            <TextArea
              id="reason"
              name="reason"
              labelFor="reason"
              labelText="Motivo"
              value={reason}
              maxLength={1000}
              onChange={e => setReason(e.target.value)}
            />
          </div>
        </div>

        <div
          style={{
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <DefaultTooltip
            title="Necessário informar o motivo"
            background={colors.danger}
            borderColor={colors.danger}
            disabled={reason !== ''}
          >
            <OptionConfirm
              type="button"
              disabled={reason === ''}
              onClick={() => handleConfirmation()}
            >
              Sim, excluir
            </OptionConfirm>
          </DefaultTooltip>

          <OptionCancel type="button" onClick={() => handleClose()}>
            Não
          </OptionCancel>
        </div>
      </div>
    </StyledModal>
  );
};

export default Delete;
