import styled, { css } from 'styled-components';

export const Container = styled.button`
  ${({ theme }) => css`
    margin-left: 0.5rem;
    padding: 8px;
    background-color: transparent;
    color: ${theme.colors.view};
    border-radius: 50%;
    box-shadow: 1px 1px 1px ${theme.colors.lightGrey};

    &:hover {
      transform: scale(1.2, 1.2);
    }
  `}
`;
