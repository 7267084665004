export default function getFormattedNumber(
  receivedNumber: number,
  decimals = 2,
  useGrouping = true,
): string {
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    useGrouping,
  }).format(receivedNumber);
}
