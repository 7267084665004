import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { FcHighPriority } from 'react-icons/fc';

import TextArea from '~/components/TextArea';

import {
  StyledModal,
  Container,
  IconContainer,
  Title,
  SubTitle,
  Content,
  Wrapper,
  ButtonContainer,
  OptionConfirm,
  OptionCancel,
} from './styles';

interface IDeleteProps {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onDelete: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setReason: Dispatch<SetStateAction<string>>;
}

const OldDelete: React.FC<IDeleteProps> = ({
  isOpen,
  title,
  subTitle,
  onDelete,
  setIsOpen,
  setReason,
}: IDeleteProps) => {
  const [errorReason, setErrorReason] = useState('');
  const [reasonReceived, setReasonReceived] = useState('');

  const handleClose = (): void => {
    setErrorReason('');
    setReasonReceived('');
    setIsOpen(false);
  };

  useEffect(() => {
    if (reasonReceived !== '') {
      setErrorReason('');
    }
  }, [reasonReceived]);

  const handleConfirmation = (): void => {
    if (!reasonReceived) {
      setErrorReason('Campo obrigatório');
    } else {
      setReason(reasonReceived);
      setReasonReceived('');
      setIsOpen(false);
      onDelete();
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Container>
        <IconContainer>
          <FcHighPriority size={60} color="#c9aa71" />
        </IconContainer>

        <Title>{title}</Title>

        {!!subTitle && <SubTitle>{subTitle}</SubTitle>}

        <Content>
          <Wrapper>
            <TextArea
              id="reason"
              name="reason"
              labelFor="reason"
              labelText="Motivo"
              defaultValue={reasonReceived}
              error={errorReason !== '' ? errorReason : undefined}
              maxLength={1000}
              onChange={e => setReasonReceived(e.target.value)}
            />
          </Wrapper>
        </Content>

        <ButtonContainer>
          <OptionConfirm type="button" onClick={() => handleConfirmation()}>
            Sim, excluir
          </OptionConfirm>

          <OptionCancel type="button" onClick={() => handleClose()}>
            Não
          </OptionCancel>
        </ButtonContainer>
      </Container>
    </StyledModal>
  );
};

export default OldDelete;
