import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import { shade, tint } from 'polished';

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 680px;
    margin-top: 20%;
    margin-left: 50%;
    transform: translate(-50%, -20%);
    background-color: ${theme.colors.background};
    box-shadow: ${theme.shadows.veryStrong};
    border-radius: ${theme.radius.meddium};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xLarge};
    text-align: center;
    color: ${theme.colors.darkGrey};
  `}
`;

export const SubTitle = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.meddium};
    text-align: center;
    margin-top: 1rem;
  `}
`;

export const OptionConfirm = styled.button`
  ${({ theme, disabled }) => css`
    width: 180px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    background-color: ${theme.colors.danger};
    color: ${theme.colors.white};
    border-radius: ${theme.radius.default};
    font-size: ${theme.fontSizes.default};
    font-weight: 400;

    ${disabled
      ? css`
          background-color: ${tint(0.3, theme.colors.danger)};
          cursor: not-allowed;
        `
      : css`
          :hover {
            transform: scale(1.1, 1.1);
            background: ${shade(0.2, theme.colors.danger)};
          }
        `}
  `}
`;

export const OptionCancel = styled.button`
  ${({ theme }) => css`
    width: 180px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
    border-radius: ${theme.radius.default};
    font-size: ${theme.fontSizes.default};
    font-weight: 400;

    :hover {
      transform: scale(1.1, 1.1);
      background: ${shade(0.2, theme.colors.secondary)};
    }
  `}
`;
