import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PropagateLoader } from 'react-spinners';
import { FaProjectDiagram, FaCalendarAlt, FaWrench } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import TableSelect from '~/components/TableSelect';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import DeletePreviousButton from '~/components/Buttons/DeletePrevious';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import ConfirmWarningDialog from '~/components/Dialogs/ConfirmWarning';
import DeleteModal from '~/components/Modals/OldDelete';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  LabelContainer,
  Label,
  Table,
  ItemSequence,
  ItemSequenceLabel,
  ItemContent,
  ItemWrapper,
  ButtonContainer,
} from './styles';

interface IPreviousProjectGift {
  id: string;
  sequence: number;
  amount: number;
  gift_type_id: string;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
}

interface IProjectGift {
  id: string;
  sequence: number;
  amount: number;
  gift_type: {
    value: string;
  };
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project_record_reason: string;
  gift_type_error: string;
  previous: boolean;
  fields_enabled: boolean;
  deleted: boolean;
}

interface IFormGifts {
  project_gifts: IProjectGift[];
  received_user_id: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
}

const Gifts: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [giftTypes, setGiftTypes] = useState<
    {
      id: string;
      name: string;
      erp_code: string;
    }[]
  >();
  const [previousProjectGifts, setPreviousProjectGifts] =
    useState<IPreviousProjectGift[]>();
  const [projectGifts, setProjectGifts] = useState<IProjectGift[]>([]);
  const [loadingGiftTypes, setLoadingGiftTypes] = useState<boolean>(true);
  const [loadingProjectGifts, setLoadingProjectGifts] = useState<boolean>(true);
  const [loadProjectGifts, setloadProjectGifts] = useState(false);
  const [projectGiftsLoaded, setProjectGiftsLoaded] = useState(false);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');
  const [reasonDelete, setReasonDelete] = useState('');
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const projectGiftSequence = useRef(0);
  const [giftEnableSequence, setGiftEnableSequence] = useState('');
  const [confirmGiftEnabled, setConfirmGiftEnabled] = useState(false);
  const [giftDeleteSequence, setGiftDeleteSequence] = useState('');
  const [confirmGiftDelete, setConfirmGiftDelete] = useState(false);

  const { handleSubmit, register, setValue } = useForm<IFormGifts>();

  useEffect(() => {
    if (loadProjectGifts) {
      if (previousProjectGifts) {
        if (previousProjectGifts.length > 0) {
          Object.keys(previousProjectGifts).forEach(key => {
            projectGiftSequence.current =
              previousProjectGifts[Number(key)].sequence;

            const previousProjectGift: IProjectGift = {
              id: previousProjectGifts[Number(key)].id,
              sequence: previousProjectGifts[Number(key)].sequence,
              amount: previousProjectGifts[Number(key)].amount,
              gift_type: {
                value: previousProjectGifts[Number(key)].gift_type_id,
              },
              project_id: previousProjectGifts[Number(key)].project_id,
              user_inclusion_id:
                previousProjectGifts[Number(key)].user_inclusion_id,
              user_update_id: user.id,
              project_record_reason: '',
              gift_type_error: '',
              previous: true,
              fields_enabled: false,
              deleted: false,
            };

            setProjectGifts(prevProjectGifts => [
              ...prevProjectGifts,
              previousProjectGift,
            ]);
          });
        }
      }

      setloadProjectGifts(false);
      setProjectGiftsLoaded(true);
      setLoadingProjectGifts(false);
    }
  }, [state, user, loadProjectGifts, previousProjectGifts]);

  useEffect(() => {
    if (previousProjectGifts) {
      if (!projectGiftsLoaded) {
        setloadProjectGifts(true);
      }
    }
  }, [projectGiftsLoaded, previousProjectGifts]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Dados não localizados',
      });

      history.push('/projects/events');
    } else if (
      user.user_type.type !== 'ADM' &&
      user.user_type.type !== 'MK1' &&
      user.user_type.type !== 'MK2' &&
      user.user_type.type !== 'MAX'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/events');
    } else {
      register('project_gifts');
      register('received_user_id');

      setValue('status', { value: 'Cancelado' });
      setValue('received_user_id', user.id);

      api.get('/configurations/gifttypes/valid').then(response => {
        setGiftTypes(response.data);
        setLoadingGiftTypes(false);
      });

      api
        .get(`/projectgifts/projectall?project_id=${state.id}`)
        .then(response => {
          setPreviousProjectGifts(response.data);
        });
    }
  }, [state, addToast, history, user, register, setValue]);

  const options = giftTypes?.map(giftType => ({
    value: giftType.id,
    label: `${giftType.name} ${
      giftType.erp_code !== null ? ` (${giftType.erp_code})` : ''
    }`,
  }));

  const submitForm = useCallback(
    async (data: IFormGifts) => {
      try {
        setIsSaving(true);

        if (state && state.id) {
          await api.put(`/projects/updategifts/${state.id}`, data);

          addToast({
            type: 'success',
            title: 'Solicitação atualizada!',
            description: 'Solicitação atualizada com sucesso.',
          });
        }

        history.push('/projects/events');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [state, addToast, history],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    const rowsInvalidType = projectGifts.filter(
      projGift => projGift.gift_type.value === '',
    );

    const rowsInvalidAmount = projectGifts.filter(
      projGift => projGift.amount <= 0,
    );

    const rowsGiftTypeError = projectGifts.filter(
      projGift => projGift.gift_type_error,
    );

    if (rowsInvalidType && rowsInvalidType.length > 0) {
      setTitleNotice('Tipos inválidos em brindes!');
      setSubTitleNotice(
        'Não é possível atualizar solicitação com registro de brindes sem tipo selecionado!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidAmount && rowsInvalidAmount.length > 0) {
      setTitleNotice('Quantidades inválidas em brindes!');
      setSubTitleNotice(
        'Não é possível atualizar solicitação com registro de brindes sem quantidade informada!',
      );
      setIsOpenNotice(true);
    } else if (rowsGiftTypeError && rowsGiftTypeError.length > 0) {
      setTitleNotice('Tipos inválidos em brindes!');
      setSubTitleNotice(
        'Não é possível atualizar solicitação com erros em registros de brindes!',
      );
      setIsOpenNotice(true);
    } else if (projectGifts.length <= 0) {
      setTitleNotice('Brindes inválidos!');
      setSubTitleNotice('Nenhum brinde foi informado!');
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(
        `Confirma alteração em registros de brindes na solicitação ${state.code}?`,
      );
      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue('project_gifts', projectGifts);
    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    history.push('/projects/events');
  }, [history]);

  function onConfirmWarning(): void {
    setConfirmGiftEnabled(true);
  }

  function onDelete(): void {
    setConfirmGiftDelete(true);
  }

  function handleAddRowTableProjectGifts(): void {
    projectGiftSequence.current += 1;

    const newProjectGift: IProjectGift = {
      id: '',
      sequence: projectGiftSequence.current,
      amount: 0,
      gift_type: { value: '' },
      project_id: state.id,
      user_inclusion_id: user.id,
      user_update_id: user.id,
      project_record_reason: '',
      gift_type_error: '',
      previous: false,
      fields_enabled: true,
      deleted: false,
    };

    setProjectGifts(prevProjectGifts => [...prevProjectGifts, newProjectGift]);
  }

  function handleDeleteRowTableProjectGifts(): void {
    setProjectGifts(
      projectGifts.filter(
        projGift => projGift.sequence !== projectGiftSequence.current,
      ),
    );

    projectGiftSequence.current -= 1;
  }

  useEffect(() => {
    Object.keys(projectGifts).forEach(key => {
      if (!projectGifts[Number(key)].deleted) {
        let conflict = false;

        const projGiftConflict = projectGifts.filter(
          projectGift =>
            !projectGift.deleted &&
            projectGift.sequence !== projectGifts[Number(key)].sequence &&
            projectGift.gift_type.value ===
              projectGifts[Number(key)].gift_type.value,
        );

        if (projGiftConflict && projGiftConflict.length > 0) {
          conflict = true;
        }

        if (conflict && projectGifts[Number(key)].gift_type_error === '') {
          setProjectGifts(
            projectGifts.map(projGift => {
              if (projGift.sequence === projectGifts[Number(key)].sequence) {
                projGift.gift_type_error = 'Tipo de brinde já utilizado';
              }

              return projGift;
            }),
          );
        } else if (
          !conflict &&
          projectGifts[Number(key)].gift_type_error !== ''
        ) {
          setProjectGifts(
            projectGifts.map(projGift => {
              if (projGift.sequence === projectGifts[Number(key)].sequence) {
                projGift.gift_type_error = '';
              }

              return projGift;
            }),
          );
        }
      }
    });
  }, [projectGifts]);

  async function handleRowGiftTypeChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectGifts(
      projectGifts.map(projGift => {
        if (projGift.sequence === sequence) {
          projGift.gift_type.value = fieldValue;
        }
        return projGift;
      }),
    );
  }

  function handleRowAmountChange(sequence: number, fieldValue: number): void {
    let amountConv = fieldValue.toString();
    let amountValue = 0;

    amountConv = amountConv.replaceAll('.', '');
    amountConv = amountConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(amountConv)))) {
      amountValue = parseFloat(amountConv);
    }

    setProjectGifts(
      projectGifts.map(projGift => {
        if (projGift.sequence === sequence) {
          projGift.amount = amountValue;
        }
        return projGift;
      }),
    );
  }

  function getGiftTypeLabel(gift_type_id: string): string {
    if (giftTypes) {
      if (giftTypes.length > 0) {
        const giftType = giftTypes.find(giftTp => giftTp.id === gift_type_id);

        if (giftType) {
          return `${giftType.name}${
            giftType.erp_code !== null ? ` (${giftType.erp_code})` : ''
          }`;
        }
      }
    }

    return 'Selecione ...';
  }

  function handleRowFieldsEnabledFalseChange(sequence: number): void {
    setProjectGifts(
      projectGifts.map(projGift => {
        if (projGift.sequence === sequence) {
          projGift.fields_enabled = false;
        }
        return projGift;
      }),
    );
  }

  useEffect(() => {
    if (confirmGiftEnabled) {
      if (giftEnableSequence) {
        if (giftEnableSequence !== '') {
          setProjectGifts(
            projectGifts.map(projGift => {
              if (projGift.sequence === Number(giftEnableSequence)) {
                projGift.fields_enabled = true;
              }
              return projGift;
            }),
          );
          setGiftEnableSequence('');
          setConfirmGiftEnabled(false);
        }
      }
    }
  }, [confirmGiftEnabled, giftEnableSequence, projectGifts]);

  useEffect(() => {
    if (confirmGiftDelete) {
      if (giftDeleteSequence) {
        if (giftDeleteSequence !== '') {
          setProjectGifts(
            projectGifts.map(projGift => {
              if (projGift.sequence === Number(giftDeleteSequence)) {
                projGift.deleted = true;
                projGift.project_record_reason = reasonDelete;
                projGift.gift_type_error = '';
              }
              return projGift;
            }),
          );

          setGiftDeleteSequence('');
          setConfirmGiftDelete(false);
          setReasonDelete('');
        }
      }
    }
  }, [confirmGiftDelete, giftDeleteSequence, projectGifts, reasonDelete]);

  function handleProjectGiftsTableRowEnableFieldsButtons(
    rowProjectGift: IProjectGift,
  ): React.ReactNode {
    if (rowProjectGift.fields_enabled) {
      return (
        <UnlockButton
          iconSize={20}
          type="button"
          onClick={() => {
            handleRowFieldsEnabledFalseChange(rowProjectGift.sequence);
          }}
        />
      );
    }

    return (
      <LockButton
        iconSize={20}
        type="button"
        onClick={() => {
          setGiftEnableSequence(rowProjectGift.sequence.toString());
          setIsOpenWarning(true);
        }}
      />
    );
  }

  function handleDeleteRow(
    projectGiftReceived: IProjectGift,
  ): React.ReactNode | null {
    if (projectGiftReceived.sequence === projectGiftSequence.current) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => {
            handleDeleteRowTableProjectGifts();
          }}
        />
      );
    }

    return null;
  }

  function handleProjectGiftsTableRowButtons(
    rowProjectGift: IProjectGift,
  ): React.ReactNode | null {
    if (rowProjectGift.previous) {
      return (
        <>
          {handleProjectGiftsTableRowEnableFieldsButtons(rowProjectGift)}

          <DeletePreviousButton
            iconSize={20}
            marginTop="0.8rem"
            marginLeft="1rem"
            type="button"
            onClick={() => {
              setGiftDeleteSequence(rowProjectGift.sequence.toString());
              setTitleDelete(
                `Deseja realmente excluir o brinde ` +
                  `"${getGiftTypeLabel(rowProjectGift.gift_type.value)}" ` +
                  `previamente registrado?`,
              );
              setSubTitleDelete(
                'A exclusão apenas será efetivada ao salvar o procedimento!',
              );
              setIsOpenDelete(true);
            }}
          />
        </>
      );
    }

    return handleDeleteRow(rowProjectGift);
  }

  function handleProjectGiftsTableRow(
    rowProjectGift: IProjectGift,
  ): React.ReactNode | null {
    if (!rowProjectGift.deleted) {
      return (
        <tr key={rowProjectGift.sequence}>
          <ItemSequence>
            <ItemSequenceLabel>
              {`${'Sequência'.toUpperCase()}: ${rowProjectGift.sequence}`}
            </ItemSequenceLabel>
          </ItemSequence>

          <ItemContent>
            <ItemWrapper>
              <TableSelect
                label="Tipo"
                upperCaseLabel
                options={options}
                loading={loadingGiftTypes}
                defaultValue={{
                  value: rowProjectGift.gift_type.value,
                  label: getGiftTypeLabel(rowProjectGift.gift_type.value),
                }}
                error={rowProjectGift.gift_type_error}
                disabled={
                  rowProjectGift.previous && !rowProjectGift.fields_enabled
                }
                onSet={(e: any) => {
                  handleRowGiftTypeChange(rowProjectGift.sequence, e.value);
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableWholeNumberInput
                type="text"
                labelText="Quantidade"
                upperCaseLabel
                defaultValue={rowProjectGift.amount}
                disabled={
                  rowProjectGift.previous && !rowProjectGift.fields_enabled
                }
                onSet={e => {
                  handleRowAmountChange(
                    rowProjectGift.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 5px 10px 10px',
            }}
          >
            {handleProjectGiftsTableRowButtons(rowProjectGift)}
          </div>
        </tr>
      );
    }

    return null;
  }

  function handleProjectGiftsTable(): React.ReactNode {
    if (projectGifts) {
      if (projectGifts.length > 0) {
        return (
          <Table>
            {loadingProjectGifts && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectGifts?.map(projectGift =>
              handleProjectGiftsTableRow(projectGift),
            )}
          </Table>
        );
      }
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/events"
        firstChild="Eventos"
        secondChild="Alteração Manual de Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        secondChildIcon={FaWrench}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} all />

      <form onSubmit={handleSubmit(submitForm)}>
        <LabelContainer>
          <Label>Brindes</Label>
        </LabelContainer>

        <Content maxWidth="680px">{handleProjectGiftsTable()}</Content>

        <ContentAddRow maxWidth="680px">
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectGifts()}
          />
        </ContentAddRow>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <ConfirmWarningDialog
        isOpen={isOpenWarning}
        setIsOpen={setIsOpenWarning}
        onConfirm={onConfirmWarning}
        title="Deseja realmente alterar o brinde previamente registrado?"
        opConfirm="Sim, alterar"
      />

      <DeleteModal
        isOpen={isOpenDelete}
        onDelete={onDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        setReason={setReasonDelete}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Gifts;
