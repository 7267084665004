import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

interface ITdProps {
  heading?: string;
}

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    white-space: nowrap;
    color: ${theme.colors.labelInput};
  `}
`;

export const Wrapper = styled(motion.section)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 680px;
    margin-bottom: 2rem;
    padding: 2rem 2rem 2rem 2rem;
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    margin: 3rem 0 0 0;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 4px;
    background-color: ${theme.colors.lightGrey};
    box-shadow: 1px 1px 6px #ececec;
    border-radius: ${theme.radius.small};

    > thead {
      @media (max-width: 995px) {
        display: none;
      }

      > tr > th {
        padding: 1.8rem 0 0 1rem;
        font-size: ${theme.fontSizes.default};
        text-transform: uppercase;
        color: ${theme.colors.grey};

        &:first-child {
          padding-left: 2rem;
        }
      }
    }

    > tbody {
      background-color: ${theme.colors.backgroundContainer};

      > tr {
        transition: all 0.25s ease;
        background-color: #fff;

        &:hover {
          transform: translateY(-1px);
          background-color: ${theme.colors.hoverTable};
          border-radius: ${theme.radius.small};
        }

        @media (max-width: 995px) {
          display: block;
          margin-bottom: 2rem;
          border-radius: ${theme.radius.small};
        }
      }
    }
  `}
`;

export const Td = styled.td<ITdProps>`
  ${({ theme, heading }) => css`
    border: 0;
    font-size: ${theme.fontSizes.small};
    padding: 1rem 0 0.5rem 1rem;
    font-weight: ${theme.weight.default};
    color: ${theme.colors.grey};

    &:first-child {
      padding-left: 2rem;

      &::before {
        border-top-left-radius: ${theme.radius.small};
      }
    }

    &:last-child {
      padding-right: 2rem;

      &::before {
        border-bottom-left-radius: ${theme.radius.small};
      }
    }

    @media (max-width: 995px) {
      display: block;
      position: relative;
      width: 100% !important;
      padding-left: 12.2rem;
      text-align: left !important;

      &:first-child {
        padding-left: 12.2rem;
      }

      &::before {
        ${heading &&
        css`
          content: '${heading}';
        `}

        width: 10rem;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        position: absolute;
        background-color: ${theme.colors.lightGrey};
        padding: 0 0.8rem;
        font-size: 14px;
        font-weight: 700;
      }
    }
  `}
`;
