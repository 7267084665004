/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import DefaultInput from '~/components/Inputs/Default';
import DetailButton from '~/components/Buttons/Detail';
import Pagination from '~/components/Pagination';
import ProjectSentInvoiceDetailModal from '~/components/Modals/Projects/SentInvoiceDetail';

import { getFormattedCurrency, getFormattedNumber } from '~/utils';

import api from '~/services/api';

import { Label, Wrapper, Table, Td } from './styles';

interface ISentInvoicesProps {
  project_id: string;
}

export interface IProjectSentInvoice {
  id: string;
  number: string;
  series: string;
  issue_date: Date;
  net_price: number;
  amount: number;
  project: {
    code: string;
    name: string;
  };
  sample?: {
    name: string;
  };
  user_inclusion: {
    name: string;
  };
  created_at: string;
}

const AMOUNT_PER_PAGE = 5;

const SentInvoices: React.FC<ISentInvoicesProps> = ({ project_id }) => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [projectSentInvoices, setProjectSentInvoices] =
    useState<IProjectSentInvoice[]>();
  const [projectSentInvoice, setProjectSentInvoice] =
    useState<IProjectSentInvoice>();
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  useEffect(() => {
    const loadProjectSentInvoices = async () => {
      await api
        .get(
          `/projectsentinvoices/project?` +
            `project_id=${project_id}&` +
            `page=${page}&` +
            `filter=${filter}&` +
            `amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setProjectSentInvoices(response.data[0]);
          setCount(response.data[1]);
        });
    };

    loadProjectSentInvoices();
  }, [project_id, page, filter]);

  if (!project_id) {
    return null;
  }

  return (
    <div style={{ maxWidth: 680, marginBottom: '1rem' }}>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Notas Fiscais Enviadas</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Wrapper>
            <div>
              <DefaultInput
                name="search"
                labelText="Pesquisar"
                type="text"
                onChange={event => {
                  setFilter(event.target.value);
                  setPage(0);
                }}
              />
            </div>

            <Table>
              <thead>
                <tr>
                  <th style={{ width: '20%' }}>Data Emissão</th>
                  <th style={{ width: '15%' }}>Número</th>
                  <th style={{ width: '15%' }}>Série</th>
                  <th style={{ width: '20%', textAlign: 'right' }}>Valor NF</th>
                  <th style={{ width: '20%', textAlign: 'right' }}>
                    Quantidade
                  </th>
                  <th style={{ width: '10%' }} />
                </tr>
              </thead>

              <tbody>
                {!projectSentInvoices ? (
                  <tr>
                    <Td
                      colSpan={6}
                      style={{ padding: '3rem', textAlign: 'center' }}
                    >
                      <PropagateLoader color="#276c8d" />
                    </Td>
                  </tr>
                ) : projectSentInvoices.length > 0 ? (
                  projectSentInvoices.map(projSentInvoice => (
                    <tr key={projSentInvoice.id}>
                      <Td
                        heading="DATA EMISSÃO"
                        style={{ width: '20%', textAlign: 'center' }}
                      >
                        {new Date(
                          projSentInvoice.issue_date,
                        ).toLocaleDateString()}
                      </Td>
                      <Td
                        heading="NÚMERO"
                        style={{ width: '15%', textAlign: 'center' }}
                      >
                        {projSentInvoice.number}
                      </Td>
                      <Td
                        heading="SÉRIE"
                        style={{ width: '15%', textAlign: 'center' }}
                      >
                        {projSentInvoice.series}
                      </Td>
                      <Td
                        heading="VALOR NF"
                        style={{ width: '20%', textAlign: 'right' }}
                      >
                        {getFormattedCurrency(projSentInvoice.net_price)}
                      </Td>
                      <Td
                        heading="QUANTIDADE"
                        style={{ width: '20%', textAlign: 'right' }}
                      >
                        {getFormattedNumber(projSentInvoice.amount, 0)}
                      </Td>
                      <Td style={{ width: '10%' }}>
                        <DetailButton
                          onClick={() => {
                            setIsOpenDetail(true);
                            setProjectSentInvoice(projSentInvoice);
                          }}
                        />
                      </Td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <Td colSpan={5} style={{ textAlign: 'center' }}>
                      Nenhuma informação disponível
                    </Td>
                  </tr>
                )}
              </tbody>
            </Table>

            <div style={{ display: 'flex', marginTop: '2rem' }}>
              <Pagination
                page={page}
                count={count}
                amountPerPage={AMOUNT_PER_PAGE}
                setPage={setPage}
              />
            </div>
          </Wrapper>
        </AccordionDetails>
      </Accordion>

      <ProjectSentInvoiceDetailModal
        isOpen={isOpenDetail}
        projectSentInvoice={projectSentInvoice}
        setIsOpen={setIsOpenDetail}
        onClose={() => setProjectSentInvoice(undefined)}
      />
    </div>
  );
};

export default SentInvoices;
