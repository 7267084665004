import React, { ButtonHTMLAttributes } from 'react';
import { FaFileAlt } from 'react-icons/fa';

import { Container } from './styles';

const Detail: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...rest
}) => {
  return (
    <Container {...rest}>
      <FaFileAlt size={22} />
    </Container>
  );
};

export default Detail;
