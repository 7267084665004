export default function getCurrencyFormatValue(
  receivedValue: string | number,
): number {
  let receivedValueConv = receivedValue.toString();
  let response = 0;

  receivedValueConv = receivedValueConv.replaceAll('R$', '');
  receivedValueConv = receivedValueConv.replaceAll('.', '');
  receivedValueConv = receivedValueConv.replaceAll(',', '.');

  if (!Number.isNaN(Number(parseFloat(receivedValueConv)))) {
    response = parseFloat(receivedValueConv);
  }

  return response;
}
