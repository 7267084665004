import styled, { css } from 'styled-components';
import Modal from 'react-modal';

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 680px;
    margin-top: 10%;
    margin-left: 50%;
    transform: translate(-50%, -10%);
    background-color: ${theme.colors.background};
    box-shadow: ${theme.shadows.veryStrong};
    border-radius: ${theme.radius.meddium};

    @media (max-width: 1010px) {
      margin-top: 0;
      margin-left: 0;
      transform: translate(0%, 0%);
      margin: auto;
    }
  `}
`;

export const Title = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.large};
  `}
`;

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    max-width: 680px;
    margin-bottom: 2rem;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 10px;
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;

    > div {
      width: 300px;

      @media (max-width: 1010px) {
        width: 100%;
      }
    }
  `}
`;
