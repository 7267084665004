import React, { useState, useEffect } from 'react';

import Representative from '~/components/ProjectSections/Representative';
import Client from '~/components/ProjectSections/Client';
import ClientPatterns from '~/components/ProjectSections/ClientPatterns';
import Main from '~/components/ProjectSections/Main';
import Samples from '~/components/ProjectSections/Samples';
import ClientContact from '~/components/ProjectSections/ClientContact';
import Records from '~/components/ProjectSections/Records';
import Files from '~/components/ProjectSections/Files';
import RequestFiles from '~/components/ProjectSections/RequestFiles';
import Amount from '~/components/ProjectSections/Amount';
import TotalPrice from '~/components/ProjectSections/TotalPrice';
import SentInvoices from '~/components/ProjectSections/SentInvoices';
import Labels from '~/components/ProjectSections/Labels';

import api from '~/services/api';

interface ISampleProps {
  project_id: string;
  all?: boolean;
}

interface IProject {
  id: string;
  type: string;
  code: string;
  price: number;
  total_price: number;
  item_total_amount: number;
  item_sent_amount: number;
  item_pending_amount: number;
  rep_as_client: boolean;
  erp_client_code: string;
  erp_rep_code: string;
  note: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  project_procedure_id: string;
  project_procedure: {
    id: string;
    sequence: number;
  };
}

const Sample: React.FC<ISampleProps> = ({ project_id, all }) => {
  const [project, setProject] = useState<IProject>();

  useEffect(() => {
    api.get(`/projects/id?id=${project_id}`).then(response => {
      setProject(response.data);
    });
  }, [project_id]);

  function handleRepresentative(): React.ReactNode | null {
    if (project) {
      return (
        <Representative
          erp_rep_code={project.erp_rep_code ? project.erp_rep_code : ''}
        />
      );
    }

    return null;
  }

  function handleClient(): React.ReactNode | null {
    if (project) {
      return (
        <Client
          rep_as_client={project.rep_as_client}
          erp_client_code={
            project.erp_client_code ? project.erp_client_code : ''
          }
          erp_rep_code={project.erp_rep_code ? project.erp_rep_code : ''}
        />
      );
    }

    return null;
  }

  function handleClientPatterns(): React.ReactNode | null {
    if (project) {
      return (
        <ClientPatterns
          erp_client_code={
            project.erp_client_code ? project.erp_client_code : ''
          }
        />
      );
    }

    return null;
  }

  function handleMain(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <Main
            code={project.code ? project.code : ''}
            note={project.note ? project.note : ''}
          />
        );
      }
    }

    return null;
  }

  function handleSamples(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return <Samples project_id={project.id} />;
      }
    }

    return null;
  }

  function handleClientContact(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <ClientContact
            client_contact_name={
              project.client_contact_name ? project.client_contact_name : ''
            }
            client_contact_phone={
              project.client_contact_phone ? project.client_contact_phone : ''
            }
            client_contact_email={
              project.client_contact_email ? project.client_contact_email : ''
            }
          />
        );
      }
    }

    return null;
  }

  function handleRecords(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return <Records project_id={project.id} />;
      }
    }

    return null;
  }

  function handleFiles(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <Files
            project_id={project.id}
            project_procedure_id={project.project_procedure.id}
          />
        );
      }
    }

    return null;
  }

  function handleRequestFiles(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <RequestFiles
            project_id={project.id}
            project_procedure_id={project.project_procedure.id}
          />
        );
      }
    }

    return null;
  }

  function handleAmount(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return (
          <Amount
            total_amount={project.item_total_amount}
            sent_amount={project.item_sent_amount}
            pending_amount={project.item_pending_amount}
          />
        );
      }
    }

    return null;
  }

  function handleTotalPrice(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 1 || all) {
        return <TotalPrice total_price={project.total_price} />;
      }
    }

    return null;
  }

  function handleSentInvoices(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 2 || all) {
        return <SentInvoices project_id={project.id} />;
      }
    }

    return null;
  }

  function handleLabels(): React.ReactNode | null {
    if (project) {
      if (project.project_procedure.sequence > 2 || all) {
        return <Labels project_id={project.id} />;
      }
    }

    return null;
  }

  return (
    <>
      {handleRepresentative()}
      {handleClient()}
      {handleClientPatterns()}
      {handleMain()}
      {handleSamples()}
      {handleClientContact()}
      {handleRecords()}
      {handleFiles()}
      {handleRequestFiles()}
      {handleAmount()}
      {handleTotalPrice()}
      {handleSentInvoices()}
      {handleLabels()}
    </>
  );
};

export default Sample;
