import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FaProjectDiagram, FaFolder } from 'react-icons/fa';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import CompleteIcon from '~/components/TimelineElements/CompleteIcon';
import PendingIcon from '~/components/TimelineElements/PendingIcon';
import Title from '~/components/TimelineElements/Title';
import Topic from '~/components/TimelineElements/Topic';
import Gradient from '~/components/TimelineElements/Gradient';
import DetailTitle from '~/components/TimelineElements/DetailTitle';
import DetailProcedure from '~/components/TimelineElements/DetailProcedure';
import DetailInfo from '~/components/TimelineElements/DetailInfo';
import Code from '~/components/TimelineItems/Code';
import Representative from '~/components/TimelineItems/Representative';
import Client from '~/components/TimelineItems/Client';
import Item from '~/components/TimelineItems/Item';
import ShippingType from '~/components/TimelineItems/ShippingType';
import FirstRequest from '~/components/TimelineItems/FirstRequest';
import Observation from '~/components/TimelineItems/Observation';
import Records from '~/components/TimelineItems/Records';
import Files from '~/components/TimelineItems/Files';
import RequestFiles from '~/components/TimelineItems/RequestFiles';
import RecordNotes from '~/components/TimelineItems/RecordNotes';
import Completion from '~/components/TimelineItems/Completion';
import ExitButton from '~/components/Buttons/Exit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import {
  Container,
  TabTitle,
  ProcedureContent,
  MainContent,
  TitleContent,
  TitleTextWrapper,
  InfoContent,
  DetailContent,
  DetailItemWrapper,
  ButtonContainer,
} from './styles';

interface IDetail {
  procedure: string;
  information: string;
  component: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  item_total_amount: number;
  item_sent_amount: number;
  item_pending_amount: number;
  rep_as_client: boolean;
  erp_client_code: string;
  erp_rep_code: string;
  note: string;
  status: string;
  shipping_type: string;
  first_request: string;
  folder: {
    code: string;
    name: string;
  };
  project_procedure: {
    sequence: number;
  };
}

const View: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [tabIndex, setTabIndex] = useState(0);
  const [detail, setDetail] = useState<IDetail>();

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/folders');
    } else if (
      user.user_type.type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/folders');
    }
  }, [addToast, history, state, user]);

  function handleFiles(
    procedure: string,
    information: string,
    component: string,
  ): JSX.Element | null {
    if (user.user_type.type !== 'REP') {
      return (
        <Topic
          label="- Anexos"
          onClick={() => {
            setDetail({ procedure, information, component });
          }}
        />
      );
    }

    return null;
  }

  function handleCompletion(
    procedure: string,
    information: string,
    component: string,
  ): JSX.Element | null {
    if (state.status === 'Finalizado') {
      return (
        <Topic
          label="- Finalização"
          onClick={() => {
            setDetail({ procedure, information, component });
          }}
        />
      );
    }

    return null;
  }

  function handleProcedure1(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence > 1
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Abertura" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="570px" />

            <div>
              <Topic
                label="- Código"
                onClick={() => {
                  setDetail({
                    procedure: 'Abertura',
                    information: 'Código',
                    component: '01_code',
                  });
                }}
              />

              <Topic
                label="- Representante"
                onClick={() => {
                  setDetail({
                    procedure: 'Abertura',
                    information: 'Representante',
                    component: '01_representative',
                  });
                }}
              />

              <Topic
                label="- Cliente"
                onClick={() => {
                  setDetail({
                    procedure: 'Abertura',
                    information: 'Cliente',
                    component: '01_client',
                  });
                }}
              />

              <Topic
                label="- Item"
                onClick={() => {
                  setDetail({
                    procedure: 'Abertura',
                    information: 'Item',
                    component: '01_item',
                  });
                }}
              />

              <Topic
                label="- Frete"
                onClick={() => {
                  setDetail({
                    procedure: 'Abertura',
                    information: 'Frete',
                    component: '01_shipping_type',
                  });
                }}
              />

              <Topic
                label="- 1º Pedido"
                onClick={() => {
                  setDetail({
                    procedure: 'Abertura',
                    information: '1º Pedido',
                    component: '01_first_request',
                  });
                }}
              />

              <Topic
                label="- Observação"
                onClick={() => {
                  setDetail({
                    procedure: 'Abertura',
                    information: 'Observação',
                    component: '01_obs',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Abertura',
                    information: 'Histórico',
                    component: '01_records',
                  });
                }}
              />

              {handleFiles('Abertura', 'Anexos', '01_files')}

              <Topic
                label="- Anexos de Requisição"
                onClick={() => {
                  setDetail({
                    procedure: 'Abertura',
                    information: 'Anexos de Requisição',
                    component: '01_request_files',
                  });
                }}
              />
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleProcedure2(): JSX.Element | null {
    if (
      state?.project_procedure &&
      state.project_procedure.sequence &&
      state.project_procedure.sequence === 2 &&
      state.item_sent_amount > 0
    ) {
      return (
        <MainContent>
          <TitleContent>
            <CompleteIcon />

            <TitleTextWrapper>
              <Title label="Inclusão NFs de Envio" />
            </TitleTextWrapper>
          </TitleContent>

          <InfoContent>
            <Gradient height="400px" />

            <div>
              <Topic
                label="- Qtd. Total"
                onClick={() => {
                  setDetail({
                    procedure: 'Inclusão NFs de Envio',
                    information: 'Qtd. Total',
                    component: '02_total_amount',
                  });
                }}
              />

              <Topic
                label="- Atualização Qtd. Enviada"
                onClick={() => {
                  setDetail({
                    procedure: 'Inclusão NFs de Envio',
                    information: 'Qtd. Enviada',
                    component: '02_sent_amount',
                  });
                }}
              />

              <Topic
                label="- Atualização Qtd. Pendente"
                onClick={() => {
                  setDetail({
                    procedure: 'Inclusão NFs de Envio',
                    information: 'Qtd. Pendente',
                    component: '02_pending_amount',
                  });
                }}
              />

              <Topic
                label="- Observações"
                onClick={() => {
                  setDetail({
                    procedure: 'Inclusão NFs de Envio',
                    information: 'Observações',
                    component: '02_notes',
                  });
                }}
              />

              <Topic
                label="- Histórico"
                onClick={() => {
                  setDetail({
                    procedure: 'Inclusão NFs de Envio',
                    information: 'Histórico',
                    component: '02_records',
                  });
                }}
              />

              {handleFiles('Inclusão NFs de Envio', 'Anexos', '02_files')}

              {handleCompletion(
                'Inclusão NFs de Envio',
                'Finalização',
                '02_completion',
              )}
            </div>
          </InfoContent>
        </MainContent>
      );
    }

    return null;
  }

  function handleCurrentProcedure(): JSX.Element | null {
    let title = '';

    if (
      state?.project_procedure &&
      state.project_procedure.sequence === 2 &&
      state.item_sent_amount <= 0
    ) {
      title = 'Inclusão NFs de Envio';
    }

    if (title !== '') {
      return (
        <TitleContent>
          <PendingIcon />

          <TitleTextWrapper>
            <Title label={title} highlighted />
          </TitleTextWrapper>
        </TitleContent>
      );
    }

    return null;
  }

  function handleDetailTitle(): JSX.Element | null {
    if (detail?.procedure || detail?.information) {
      return <DetailTitle />;
    }

    return null;
  }

  function handleDetailProcedure(): JSX.Element | null {
    if (detail?.procedure) {
      return <DetailProcedure label={detail.procedure} />;
    }

    return null;
  }

  function handleDetailInformation(): JSX.Element | null {
    if (detail?.information) {
      return <DetailInfo label={detail.information} />;
    }

    return null;
  }

  function handleDetailComponent(): JSX.Element | null {
    if (detail?.component === '01_code') {
      return <Code code={state?.code ? state.code : ''} />;
    }

    if (detail?.component === '01_representative') {
      return (
        <Representative
          erp_rep_code={state?.erp_rep_code ? state.erp_rep_code : ''}
        />
      );
    }

    if (detail?.component === '01_client') {
      return (
        <Client
          rep_as_client={state.rep_as_client}
          erp_client_code={state?.erp_client_code ? state.erp_client_code : ''}
          erp_rep_code={state?.erp_rep_code ? state.erp_rep_code : ''}
        />
      );
    }

    if (detail?.component === '01_item') {
      return (
        <Item
          code={state?.folder.code ? state.folder.code : ''}
          name={state?.folder.name ? state.folder.name : ''}
        />
      );
    }

    if (detail?.component === '01_shipping_type') {
      return <ShippingType shipping_type={state?.shipping_type} />;
    }

    if (detail?.component === '01_first_request') {
      return <FirstRequest first_request={state?.first_request} />;
    }

    if (detail?.component === '01_obs') {
      return <Observation note={state?.note ? state.note : ''} />;
    }

    if (detail?.component === '01_records') {
      return <Records project_id={state?.id} type="PASTA" sequence={1} />;
    }

    if (detail?.component === '01_files') {
      return <Files project_id={state?.id} type="PASTA" sequence={1} />;
    }

    if (detail?.component === '01_request_files') {
      return <RequestFiles project_id={state.id} />;
    }

    if (detail?.component === '02_notes') {
      return <RecordNotes type="PASTA" sequence={2} project_id={state.id} />;
    }

    if (detail?.component === '02_records') {
      return <Records project_id={state?.id} type="PASTA" sequence={2} />;
    }

    if (detail?.component === '02_files') {
      return <Files project_id={state?.id} type="PASTA" sequence={2} />;
    }

    if (detail?.component === '02_completion') {
      return <Completion type="PASTA" sequence={2} project_id={state.id} />;
    }

    return null;
  }

  function handleProcedure1Detail(): JSX.Element | null {
    if (detail?.procedure === 'Abertura') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  function handleProcedure2Detail(): JSX.Element | null {
    if (detail?.procedure === 'Inclusão NFs de Envio') {
      return (
        <DetailContent>
          {handleDetailTitle()}

          {handleDetailProcedure()}

          {handleDetailInformation()}

          <DetailItemWrapper>{handleDetailComponent()}</DetailItemWrapper>
        </DetailContent>
      );
    }

    return null;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/folders"
        firstChild="Pastas"
        secondChild="Visualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaFolder}
        secondChildIcon={FaFolder}
        mainColored
        firstChildColored
      />

      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          <Tab>
            <TabTitle>Linha do Tempo</TabTitle>
          </Tab>
          <Tab>
            <TabTitle>Geral</TabTitle>
          </Tab>
        </TabList>

        <TabPanel>
          <ProcedureContent>
            {handleProcedure1()}
            {handleProcedure1Detail()}
          </ProcedureContent>

          <ProcedureContent>
            {handleProcedure2()}
            {handleProcedure2Detail()}
          </ProcedureContent>

          {handleCurrentProcedure()}
        </TabPanel>

        <TabPanel>
          <ProjectInfo project_id={state.id} all />
        </TabPanel>
      </Tabs>

      <ButtonContainer>
        <Link
          to={{
            pathname: '/projects/folders',
          }}
        >
          <ExitButton type="button" />
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default View;
