import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaProjectDiagram, FaBoxOpen } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import RejectButton from '~/components/Buttons/Reject';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import RejectProjectModal from '~/components/Modals/RejectProject';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { getFormattedCurrency } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, Content, Wrapper, ButtonContainer } from './styles';

interface IFormProcedure2 {
  record_note: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  price: number;
  erp_rep_code: string;
  status: string;
  project_procedure_id: string;
  project_procedure: {
    user_type: string;
  };
}

const Procedure2: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const validationType = useRef('APROVADO');
  const projectTemp = useRef(uuidv4());
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenRejectProject, setIsOpenRejectProject] = useState(false);
  const reasonRejectProject = useRef('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, errors } = useForm<IFormProcedure2>();

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/samples');
    } else if (
      user.user_type.type !== state.project_procedure.user_type &&
      user.user_type.type !== 'ADM' &&
      !(
        state.project_procedure.user_type === 'MK3' &&
        (user.user_type.type === 'MK1' || user.user_type.type === 'MK2')
      )
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Tipo de usuário não permitido',
      });

      history.push('/projects/samples');
    } else if (
      state.project_procedure.user_type === 'REP' &&
      state.erp_rep_code !== user.erp_rep_code &&
      user.user_type.type !== 'ADM'
    ) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Usuário não permitido',
      });

      history.push('/projects/samples');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/samples');
    }
  }, [addToast, history, state, user]);

  const submitForm = useCallback(
    async (data: IFormProcedure2) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, {
          ...data,
          code: state.code,
          project_procedure_id: state.project_procedure_id,
          project_temp_id: projectTemp.current,
          record_description:
            validationType.current === 'CANCELADO'
              ? 'Rejeição marketing'
              : 'Aprovação de solicitação',
          record_reason:
            validationType.current === 'CANCELADO'
              ? reasonRejectProject.current
              : '',
          type: 'AMOSTRA',
          validation_type: validationType.current,
        });

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/samples');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [
      addToast,
      history,
      projectTemp,
      reasonRejectProject,
      state,
      validationType,
    ],
  );

  const onExit = () => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp.current}`);

    history.push('/projects/samples');
  };

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/samples"
        firstChild="Amostras"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBoxOpen}
        secondChildIcon={FaBoxOpen}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp.current}
            project_type="AMOSTRA"
            sequence={2}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => setIsOpenConfirmProcedure(true)}
          />

          <RejectButton
            style={{ marginLeft: '1rem' }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenRejectProject(true)}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => {
          validationType.current = 'APROVADO';

          handleSubmit(submitForm)();
        }}
        title={
          `Confirma aprovação do projeto ${state.code}, ` +
          `no valor de ${getFormattedCurrency(state.price)}?`
        }
        subTitle="A solicitação será enviada para o próximo procedimento"
        opConfirm="Sim"
      />

      <RejectProjectModal
        isOpen={isOpenRejectProject}
        onReject={reason => {
          validationType.current = 'CANCELADO';
          reasonRejectProject.current = reason;

          handleSubmit(submitForm)();
        }}
        title={`Deseja realmente recusar a solicitação ${state.code}?`}
        subTitle={
          'A solicitação será cancelada e esta ' +
          'operação não poderá ser desfeita!'
        }
        setIsOpen={setIsOpenRejectProject}
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default Procedure2;
