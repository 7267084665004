import React, { Dispatch, SetStateAction } from 'react';

import InfoInput from '~/components/Inputs/Info';
import CloseButton from '~/components/Buttons/Close';

import { IProjectSentInvoice } from '~/components/ProjectSections/SentInvoices';

import { StyledModal, Title, Container } from './styles';

interface ISentInvoiceDetailProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  projectSentInvoice?: IProjectSentInvoice;
}

const SentInvoiceDetail: React.FC<ISentInvoiceDetailProps> = ({
  isOpen,
  onClose,
  setIsOpen,
  projectSentInvoice,
}: ISentInvoiceDetailProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div style={{ padding: '1rem' }}>
        <Title>Detalhes Notas Fiscais Enviadas</Title>

        <Container>
          <div>
            <InfoInput
              name="project_code"
              labelFor="project_code"
              labelText="Cód. Projeto"
              type="text"
              defaultValue={
                projectSentInvoice && projectSentInvoice.project.code
              }
              disabled
            />
          </div>

          <div>
            <InfoInput
              name="user_inclusion"
              labelFor="user_inclusion"
              labelText="Usuário"
              type="text"
              defaultValue={
                projectSentInvoice && projectSentInvoice.user_inclusion.name
              }
              disabled
            />
          </div>

          <div>
            <InfoInput
              name="number"
              labelFor="number"
              labelText="Número"
              type="text"
              defaultValue={projectSentInvoice && projectSentInvoice.number}
              disabled
            />
          </div>

          <div>
            <InfoInput
              name="series"
              labelFor="series"
              labelText="Série"
              type="text"
              defaultValue={projectSentInvoice && projectSentInvoice.series}
              disabled
            />
          </div>

          <div>
            <InfoInput
              name="issue_date"
              labelFor="issue_date"
              labelText="Data Emissão"
              type="text"
              defaultValue={
                projectSentInvoice &&
                new Date(projectSentInvoice.issue_date).toLocaleDateString()
              }
              disabled
            />
          </div>

          <div>
            <InfoInput
              name="created_at"
              labelFor="created_at"
              labelText="Data Inclusão"
              type="text"
              defaultValue={
                projectSentInvoice &&
                new Date(projectSentInvoice.created_at).toLocaleDateString()
              }
              disabled
            />
          </div>

          <div>
            <InfoInput
              name="sample"
              labelFor="sample"
              labelText="Amostra"
              type="text"
              defaultValue={
                projectSentInvoice && projectSentInvoice.sample?.name
              }
              disabled
            />
          </div>

          <div>
            <InfoInput
              name="net_price"
              labelFor="net_price"
              labelText="Valor Nota Fiscal"
              type="text"
              defaultValue={
                projectSentInvoice &&
                new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(projectSentInvoice.net_price)
              }
              disabled
            />
          </div>

          <div>
            <InfoInput
              name="amount"
              labelFor="amount"
              labelText="Quantidade"
              type="text"
              defaultValue={
                projectSentInvoice &&
                new Intl.NumberFormat('pt-BR').format(projectSentInvoice.amount)
              }
              disabled
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '2rem',
            }}
          >
            <CloseButton onClick={() => handleClose()} />
          </div>
        </Container>
      </div>
    </StyledModal>
  );
};

export default SentInvoiceDetail;
